
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ProductListModel } from "@/domain/product/product-list/model/ProductListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

export default defineComponent({
  name: "module-list",
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const swalNotification = new SwalNotification();
    const currentPage = ref(1);
    const pageSize = ref(5);
    const totalItems = ref(0);
    const pageCount = ref(1);
    const productController = store.state.ControllersModule.productController;
    const productList = ref<ProductListModel[]>([]);

    const getModuleList = async () => {
      isLoading.value = true;

      const productListModel: ProductListModel = {
        product: {
          productPackages: []
        },
        locale: "tr",
      };

      productController
        .productList(productListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((product) => {
              productList.value.push(product);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t("modalList.title"));
      getModuleList();
    });

    return {
      PAGE_TITLE_PATH,
      currentPage,
      pageCount,
      pageSize,
      isLoading,
      totalItems,
      productList,
    };
  },
});
